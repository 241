module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"De Koepel","short_name":"De Koepel","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/Favicon-new.png","icons":[{"src":"/images/icon256.png","sizes":"256x256"},{"src":"/images/icon-android96.png","sizes":"96x96"},{"src":"/images/icon76.png","sizes":"76x76"},{"src":"/images/icon-android72.png","sizes":"72x72"},{"src":"/images/icon60.png","sizes":"60x60"},{"src":"/images/icon-android48.png","sizes":"48x48"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"422820262c58351cfb435b0ffd9d49aa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
